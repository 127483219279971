import image from '../assets/logo.png'

const About = () => {

    let dataPrueba = {
        text:
          "Radio Cultural Upala sale al aire por primera  vez el 19 de noviembre de 1983. Esta emisora es parte de un proyecto de 20 estaciones que se instalarían en todo el país, para lo cual se firmó un Convenio entre el Gobierno de Costa Rica y el Principado de Liechtenstein, que hace la donación para financiar la compra de los equipos que en ese momento se requería; hasta a la fecha hay instaladas 15 emisoras.\nEn sus inicios la programación era de dos horas y transmitía en 1600 Khz. A M (amplitud modulada), así paulatinamente fue ampliando su programación y horario. Actualmente la Emisora transmite en 88.3 F M, se Transmite las 24 horas cada día.\nRadio Cultural Upala está afiliada a la Red de Emisoras de ICER (Instituto Costarricense de Enseñanza Radiofónica) y el proyecto El Maestro en Casa, sistema de estudios dirigido en especial a personas jóvenes y adultos que por diversas razones no habían podido terminar su educación primaria o secundaria. A través de esta Red de Emisoras se imparten los programas radiofónicos elaborados por los docentes de El Maestro en Casa y constituyen una herramienta útil para que el estudiante repase los contenidos y aprenda nuevos conocimientos.\nBrinda programación variada de música, noticias, programas de entretenimiento,  educativo y culturales."
    };

    return (
        <>
            {dataPrueba && (
                <div className='card presentacion'>
                    <p>{dataPrueba.text}</p> 
                    <img src={image} loading="lazy" alt='Colaborador'/>
                </div>
            )}
        </>
     );
}
 
export default About;