import Swal from 'sweetalert2'

const Contacto = () => {
    window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6Lf3HW0bAAAAAISb8N03JTERdP9JDdoAkwMkNaz-", {
            action: "validate_captcha",
          })
          .then(function (token) {
            document.getElementById("grecaptcha").value = token;
          });
      });

    function validateMail(email){
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (emailRegex.test(email)) 
            return true;
        return false;
    }

    async function hanldesSubmit(e){
        e.preventDefault();
        let topPos = document.getElementById('containerMain').offsetTop;
        var data = new FormData(e.target);
        const dataJson = JSON.stringify(Object.fromEntries(data.entries()));
        if(validateMail(JSON.parse(dataJson).email)){
            let response = await fetch(`${process.env.REACT_APP_URL}/contact`, {
            method: "POST",
            body: dataJson,
            });
            if (response.ok){
                Swal.fire({
                    icon: 'success',
                    title: 'Mensaje Enviado',
                    text: 'Su correo se ha enviado correctamente!',
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Favor intentarlo nuevamente!',
                }); 
            }
            
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Correo Incorrecto',
                text: 'Favor intentarlo nuevamente!',
            });
        }
        document.getElementById('form').reset();
        document.getElementById('name').focus();
        window.scrollTo({
            top: topPos,
            behavior: 'smooth',
        });
    }

    return ( 
        <form className="card contact" id="form" onSubmit={hanldesSubmit}>
            <h3 style={{marginTop: '5vh'}}>Contacto</h3>
            <input type="hidden" id="grecaptcha" name="grecaptcha" />
            <input type="hidden" name="action" value="validate_captcha" />
            <input type="text" placeholder="Nombre" id="name" name="name" required/>
            <input type="text" placeholder="Correo Electronico" id="email" name="email" required/>
            <input type="number" placeholder="Telefono" id="phone" name="phone"/>
            <div style={{marginTop: '5vh'}}>
                <label for="mensaje" style={{fontSize: '1.8rem',color:'gray'}}>Mensaje</label>
                <textarea id="message" name="message" cols="30" rows="10" placeholder=" Escribe tu mensaje aqui" required></textarea>
            </div>
            <button type='submit' style={{marginTop: '5vh'}}>Enviar</button>
        </form>
     );
}
 
export default Contacto;