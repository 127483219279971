import image from '../../assets/radioUpala.jpg'

const Footer = () => {
    return (
        <footer>    
            <a href="/" rel="noreferrer"><img src={image} loading="lazy" alt='Radio Upala'/></a>
            <div>
                <p>Radio Cultural de Upala</p>
                <p>88.3 FM</p>
                <p>Marcando diferencia</p>
            </div>    
            <div>
                <a href="https://www.facebook.com/Radio-Cultural-Upala-883-FM-914799328690277" target="_blank"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                <a href="https://api.whatsapp.com/send?phone=50686118330" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                <a href="https://www.instagram.com/radioupala/?hl=es-la" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
            </div>
            <p>2021 &copy; Jeosgram</p>
        </footer>    
    );
}
 
export default Footer;