import React,{useEffect,useState} from 'react';

const Concursos = () => {

    const [concursos,setConcursos]=useState([])

    useEffect(() => {
        async function loadData() {
            try {
              let response = await fetch(`${process.env.REACT_APP_URL}/concursos`);
              response = await response.json();
              if (response.ok) {
                  console.log(response)
                setConcursos(response.data);
              } else console.log("Algo salio mal en loadData! concursos");
            } catch (error) {
              console.log(error);
            }
        }
        loadData();
        
    }, [])

    return ( 
        <>
            {concursos && concursos.length>0 && (
                concursos.map(concurso=>(
                    <div className="card" key={concurso.body} >
                        <h5>{concurso.title}</h5>
                        <a href={concurso.url} target="_blank">
                            <img src={`${process.env.REACT_APP_URL}/img/${concurso.img}`} loading="lazy" alt={concurso.title}/>
                        </a>
                        <p>{concurso.description}</p>
                    </div>
                )))
            }
        </>
     );
}
 
export default Concursos;