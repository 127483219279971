import image from "../../assets/radioUpala.jpg";
import { useHistory,useLocation } from "react-router-dom";
import useSWR from 'swr';

const fetcher = () => fetch(`${process.env.REACT_APP_URL}/stream/radioupala`).then(res => res.json())

const Menu = () => {

    let history = useHistory();
    const location = useLocation();

    function Stream () {
        const { data, error } = useSWR('/', fetcher,{ refreshInterval: 10000 })
      
        if (error) return <div>Error de Streaming</div>
        if (!data) return null;
       
        return (
            <>
                {data.ok && data.show_listeners && (
                    <p id="live">Live {data.listeners}</p>
                )}
                <audio controls>
                    <source src={`${data.listenurl}`}/>
                </audio>
            </>
        );
      }

    function addClass() {
        let buttonMenu = document.getElementsByClassName("buttonMenu")[0];
        buttonMenu.classList.toggle("change");
    }

    function selectedClass(x) {
        let menuList = document.querySelectorAll("ul li");
        menuList.forEach((optionMenu) => {
          if (optionMenu == x){
            if(!optionMenu.classList.contains("selected")){
                optionMenu.classList.add("selected");
            }
          }
          else optionMenu.classList.remove("selected");
        });
    }
      
    function handleClick(path,element){
        history.push(path);
        let menu = document.getElementById("menu");
        let buttonMenu = document.getElementsByClassName("buttonMenu")[0];
        let topPos = document.getElementById('containerMain').offsetTop;
        window.scrollTo({
            top: topPos-100,
            behavior: 'smooth',
        });
        menu.checked = false;
        if (window.outerWidth <= 768) {
            menu.checked = false;
            addClass(buttonMenu);
        }
        if (location.pathname!==path){
            selectedClass(element);
        }
    }

    return (
        <>
            <input type="checkbox" name="menu" id="menu" hidden />
            <div id="containerButton">
                <label for="menu" className="buttonMenu" onClick={addClass}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </label>
                <a
                    href="/"
                    rel="noreferrer"
                    style={{ display: "none" }}
                >
                    Radio Upala
                </a>
            </div>
            <div id="containerMenu">
                <a href="/" rel="noreferrer"><img src={image} loading="lazy" alt='Radio Upala'/></a>
                <nav>
                    {Stream()}
                    <ul>
                        <a><li onClick={function(){handleClick("/",this)}} className={`${location.pathname === "/" ? 'selected' : ''}`}>Quiénes somos</li></a>
                        <a><li onClick={function(){handleClick("/concursos",this)}} className={`${location.pathname === "/concursos" ? 'selected' : ''}`}>Concursos</li></a>
                        <a><li onClick={function(){handleClick("/colaboradores",this)}} className={`${location.pathname === "/colaboradores" ? 'selected' : ''}`}>Colaboradores</li></a>
                        <a><li onClick={function(){handleClick("/contacto",this)}} className={`${location.pathname === "/contacto" ? 'selected' : ''}`}>Contacto</li></a>
                    </ul>
                </nav>
            </div>
      </>
    );
}
 
export default Menu;