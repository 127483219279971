import React,{useState,useEffect} from 'react';
import {getAnuncios} from '../../utils';
import {getPublicidad} from '../../utils';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Aside = () => {

    const [dataAnuncios,setDataAnuncios]=useState({});
    const [dataPub_licidad,setDataPub_licidad]=useState({});

    useEffect(() => {
        getAnuncios()
            .then(response => response.json())
            .then((response)=>{
                setDataAnuncios(response);
            }
        )
    }, []);

    useEffect(() => {
        getPublicidad()
            .then(response => response.json())
            .then((response)=>{
                setDataPub_licidad(response.data);
            }
        )
    }, []);

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        fade: false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        className:'slides',
        lazyLoad: true,
        pauseOnHover: true,
    };

    return ( 
        <div id='aside'>
            <div id="anuncios">
                {dataAnuncios.data ?
                    <Slider {...settings}>
                        { dataAnuncios.data.map(slider=>(
                        <div className="showSlide" key={slider.img}>  
                            <a href={slider.url} target="_blank">
                                <img src={`${process.env.REACT_APP_URL}/img/${slider.img}`}/>  
                            </a>
                            <p>{slider.title}</p>  
                        </div>
                        ))}
                    </Slider>
                    :<h2>No hay anuncios</h2>
                }
            </div>
            <div id="pub_licidad">
                {dataPub_licidad.length>0 ?
                    <Slider {...settings}>
                        {dataPub_licidad.map(slider=>(
                        <div className="showSlide" key={slider.img}> 
                            <a href={slider.url} target="_blank">
                                <img src={`${process.env.REACT_APP_URL}/img/${slider.img}`}/>  
                            </a>
                            <p>{slider.title}</p>  
                        </div>
                        ))}
                    </Slider>
                    :<h2>No hay pub_licidad</h2>
                }
            </div>
        </div>
     );
}
 
export default Aside;