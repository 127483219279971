import React,{useEffect,useState} from 'react';

const Colaboradores = () => {
    const [colaboradores,setColaboradores]=useState([])

    useEffect(() => {
        async function loadData() {
            try {
              let response = await fetch(`${process.env.REACT_APP_URL}/colaboradores`);
              response = await response.json();
              if (response.data) {
                setColaboradores(response.data);
              } else console.log("Algo salio mal en loadData colaboradores!");
            } catch (error) {
              console.log(error);
            }
        }
        loadData();
    }, [])
    
    return ( 
        <>
            {colaboradores && colaboradores.length>0 && (
                colaboradores.map(colaborador=>(
                    <div className='card personImage' key={colaborador.name}>
                        <h5>{colaborador.name}</h5>
                        <img src={`${process.env.REACT_APP_URL}/img/${colaborador.img}`} loading="lazy" alt={colaborador.name}/>
                        <p>{colaborador.about}</p>
                    </div>
                )))
            }
        </>
     );
}
 
export default Colaboradores;