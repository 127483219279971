import About from "./pages/About";
import Concursos from "./pages/Concursos";
import Colaboradores from "./pages/Colaboradores";
import Contacto from "./pages/Contacto";

import Menu from "./components/global/Menu";
import Aside from "./components/global/Aside";
import Footer from "./components/global/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./index.css";

const App = () => {
  return (
    <div className="app">
      <Router>
        <Aside />
        <Menu />
        <div id="containerMain">
          <Switch>
            <Route exact path="/concursos" component={Concursos} />
            <Route exact path="/colaboradores" component={Colaboradores} />
            <Route exact path="/contacto" component={Contacto} />
            <Route exact path="/*" component={About} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
